body {
	background: url('/img/background.jpg');
	background-size: cover;
	background-position: top;
}

.box {
	margin-top: 150px;
	margin-left: 60px;
	color: #454646;
	width: 45%;
	float: left;
	padding: 30px;

	//-webkit-box-shadow: -1px -1px 17px 0px #454646;
	//box-shadow: -1px -1px 17px 0px #454646;
	border-radius: 3px;

	background-color: rgba(255, 255, 255, 0.9);
	@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
		-webkit-backdrop-filter: blur(15px);
		backdrop-filter: blur(15px);
		background-color: rgba(255, 255, 255, 0.5);
	}
	h2 {
		font-size: 22px;
	}
	p {
		font-size: 18px;
	}
}

.iphone {
	position: relative;
	margin-left: 15%;
	float: left;
	width: 450px;
	height: 835px;
	.iphone-status-bar {
		color: white;
		opacity: 0.8;
		position: absolute;
		top: 0px;
		left: 100px;
		top: 52px;
		left: 76px;
		width: 300px;
		svg {
			float: right;
			color: white;
			margin-left: 3px;
			margin-top: 4px;
		}
		span {
			font-size: 12px;
		}
	}
	.iphone-home-bar {
		color: white;
		position: absolute;
		bottom: 50px;
		opacity: 0.8;
		width: 100%;

		span {
			width: 120px;
			display: block;
			position: relative;
			background-color: white;
			height: 4px;
			margin: 0 auto;
			border-radius: 3px;
		}
	}
	.iphone-frame {
		position: absolute;
		width: 450px;
		height: 850px;
		background: url('/img/iphone_transparent.png');
		background-size: cover;
		background-position: top;
	}
	.black-background {
		background: #171717;
		position: absolute;
		left: 50px;
		top: 46px;
		width: 350px;
		height: 757px;
	}
	iframe {
		position: absolute;
		border: 0px;
		height: 680px;
		width: 345px;
		left: 52px;
		top: 83px;
	}
}

.qr-code {
	max-width: 150px;
	border: 10px solid white;
}
